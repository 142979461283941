import { ApiHandler } from "@/lib/ApiHandler";

class ZoomService extends ApiHandler {
  constructor() {
    super("/api/liveSessions");
  }

  async getApiKey(data) {
    return await this.get("zoom-apikey", data)
      .then(res => res.json())
      .then(res => res.result);
  }

  async generateSignature(data) {
    return await this.get("zoom-token", data)
      .then(res => res.json())
      .then(res => res.result);
  }

  getMeetingDataFromUri(uri) {
    const result = {
      number: "",
      password: "",
      returnUrl: "",
      eventId: ""
    };

    // get meeting password
    const queryString = uri.substr(uri.indexOf("?"));
    let pairs = (queryString[0] === "?"
      ? queryString.substr(1)
      : queryString
    ).split("&");
    pairs.forEach(pair => {
      const pairSplit = pair.split("=");
      if (pairSplit[0] === "pwd") {
        result.password = pairSplit[1];
      }
    });

    // get meeting number id
    const url = uri.substr(0, uri.length - queryString.length);
    const urlSections = url.split("/");
    result.number = urlSections[urlSections.length - 1];

    return result;
  }
}

export const zoomService = new ZoomService();
