
<template>
  <div v-if="isReady" class="zoom-embed"></div>
</template>

<script>
import Base from "@/mixins/base";
import Component, { mixins } from "vue-class-component";
import { localStorageService } from "@/services/localStorageService";
import { zoomService } from "@/services/zoomService";
import { ZoomMtg } from "@zoomus/websdk";

@Component({
  watch: {
    zoomApiKey(newVal) {
      this.meetingConfig.apiKey = newVal;
    },
  },
})
export default class Zoom extends mixins(Base) {
  isReady = false;
  meetingConfig = {
    signature: "",
    meetingNumber: "",
    passWord: "",
    apiKey: "",
    userName: "",
    userEmail: "",
  };

  get isClosing() {
    return this.$route.query?.closeLiveSession == "1";
  }

  zoomInit() {
    // For Local module default:
    ZoomMtg.setZoomJSLib(
      "https://dmogdx0jrul3u.cloudfront.net/1.9.1/lib",
      "/av"
    );
    // Prepare Required Files
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    setTimeout(() => {
      this.zoomOpenMeeting();
    }, 1000);
  }

  zoomOpenMeeting() {
    ZoomMtg.init({
      leaveUrl: this.$router.currentRoute.fullPath + "&closeLiveSession=1",
      success: async () => {
        ZoomMtg.i18n.load(localStorageService.getCurrentLang());
        ZoomMtg.i18n.reload(localStorageService.getCurrentLang());

        this.meetingConfig.signature = await zoomService.generateSignature({
          number: this.meetingConfig.meetingNumber,
          eventId: this.$router.currentRoute.query.eventId,
        });

       // eslint-disable-next-line no-unused-vars
        this.meetingConfig.success = (success) => {
          ZoomMtg.getAttendeeslist({});
          ZoomMtg.getCurrentUser({});
        };

        ZoomMtg.join(this.meetingConfig);
      },
    });
  }

  async init() {
    const meetingAccessData = zoomService.getMeetingDataFromUri(
      this.$router.currentRoute.query.meeting
    );
    this.meetingConfig.meetingNumber = parseInt(meetingAccessData.number);
    this.meetingConfig.passWord = meetingAccessData.password;

    this.meetingConfig.apiKey = await zoomService.getApiKey({
      eventId: this.$router.currentRoute.query.eventId,
    });

    const loggedUser = localStorageService.getLoggedUser();
    this.meetingConfig.userName = `${loggedUser.firstName} ${loggedUser.lastName}`;

    this.zoomInit();

    this.isReady = true;
  }

  created() {
    if (this.isClosing) window.top.location.href = "/live-sessions";
  }

  mounted() {
    this.init();
  }
}
</script>

<style>
#zmmtg-root {
  background-color: #fff;
}
</style>
<style lang="scss" scoped>
@import "https://source.zoom.us/1.9.1/css/bootstrap.css";
@import "https://source.zoom.us/1.9.1/css/react-select.css";
</style>
